<template>
	<div class="content" :style="cssVars">
		<vue-headful :title="pageTitle" />

		<loading :active.sync="isLoading" :is-full-page="true" />

		<main v-if="!isLoading">
			<div v-if="!museumFetchingError && museum.logoImageUrl" class="logo">
				<img width="640" height="360" class="logo-image" :src="museum.logoImageUrl" alt="">
			</div>
		</main>

		<span v-if="mode" class="mode">{{ mode }}</span>
		<span v-if="localVersion" class="version">Local: v{{ localVersion }} - Last: v{{ lastVersion }}</span>

		<span v-if="!isLoading && museum.privacyPolicyEnabled" class="privacy-policy-msg"
			v-html="$t('privacy_policy_accept').replace('%1$d', $t('privacy_policy'))" />
		<button v-show="!museumFetchingError" class="button" @click="startVisit()">
			{{ $t("start_visit") }}
		</button>
		<button v-show="!isLoading && museumFetchingError" class="button error">{{ $t("error_museum_deactivated")
		}}</button>
	</div>
</template>

<script>
const { debug } = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { setFullScreen } from "@/store/helpers";
import compareDatesToday from "../../utils/compareDatesToday";
import MuseumsAPI from "@/api/MuseumsAPI";


export default {
	name: "Presentation",

	components: {
		Loading
	},

	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
			localVersion: localStorage.getItem('version'),
			lastVersion: process.env.VUE_APP_VERSION,
			mode: '',
			codeQueryParam: this.$route.query.code
		};
	},

	computed: {
		...mapState({
			museum: state => state.museum.museum,
			visit: state => state.app.visit,
			visitv2: state => state.app.visitv2,
			customization: state => state.museum.customization,
			savingResource: state => state.museum.savingResource,
			museumFetchingError: state => state.museum.museumFetchingError,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
		}),

		cssVars() {
			return {
				'--bg-image': 'url(' + ((this.museum && this.museum.hasOwnProperty('homeImageUrl')) ? this.museum.homeImageUrl : '') + ')',
				'--main-buttons-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsColor : '#72c4d0',
				'--main-buttons-text-color': (this.customization?.mainButtonsCustomizationEnabled) ? this.customization.mainButtonsTextColor : '#ffffff',
			};
		},

		pageTitle() {
			let museumName = (this.museum && this.museum.name)
				? this.museum.name + ' | '
				: (this.museum && this.museum.locale && this.museum.locale.name)
					? this.museum.locale.name + ' | '
					: "";

			return museumName + 'MuseumMate';
		}
	},

	created() {
		debug.log("[Presentation] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);

		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];

			this.mode = localStorage.getItem('mode');

			this.loadMuseumPresentation(this.idMuseum)
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch (e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},

	methods: {
		...mapActions('app', ['loadMuseum', 'loadMuseumPresentation', 'resetMuseum']),
		...mapActions('museum', ['fetchMuseumDetail', 'getMuseumFromDB']),

		
		async startVisit() {
			setFullScreen();
			const startDateCondition = JSON.parse(localStorage.getItem("visitv2"))?.startDate;
			let languagesV2 = localStorage.getItem('languagesV2'); 
			
			if(languagesV2) {
				languagesV2 = JSON.parse(languagesV2);
			} else {
        if (this.isNetworkOnline){
          languagesV2 = await MuseumsAPI.getLanguagesByMuseum(+this.visit.idMuseum);
        }
			}
			
			if(compareDatesToday(startDateCondition)) {
				const languageId = languagesV2.find(e => e.code === this.visit.languageCode)?.id || languagesV2[0]?.id || 1;
				const languageCode = languagesV2.find(e => e.code === this.visit.languageCode)?.code || languagesV2[0]?.code || 'es_ES';
        //
        if (this.isNetworkOnline){
          MuseumsAPI.registerVisitMuseumV2(+this.visit.idMuseum, languageId, languageCode, this.visit.idDevice, mutationTypes.SET_VISITV2, this.$store);
        }
			}
			
			//Checks version
			debug.open("Checking version");
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
			let version = localStorage.getItem('version');
			debug.log("localVersion: " + version + " - lastVersion: " + process.env.VUE_APP_VERSION);
			if (!version || version && version !== process.env.VUE_APP_VERSION) {
				debug.log("Version is different (" + version + ") from last version (" + process.env.VUE_APP_VERSION + "), resetting museum");
				await this.resetMuseum();
				localStorage.setItem('current-museum', this.idMuseum);
			}
			debug.close();

			//Finally do the redirection
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
			if (this.codeQueryParam) {
				await this.$router.push({ name: 'Code', params: { idMuseum: this.encrypted }, query: { code: this.codeQueryParam	} });
			} else {
				await this.$router.push({ name: 'Code', params: { idMuseum: this.encrypted }});
			}
			
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
	height: 100%;

	main {
		position: relative;
		width: 100%;
		height: 100%;
		background-image: var(--bg-image);
		background-color: white;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;

		.logo {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: auto;
			padding-top: 75px;

			.logo-image {
				width: 70%;
				height: auto;
			}
		}
	}

	.mode {
		position: absolute;
		top: 5px;
		left: 5px;
		color: rgba(255, 255, 255, 0.35);
		font-size: 12px;
	}

	.version {
		position: absolute;
		top: 5px;
		right: 5px;
		color: rgba(0, 0, 0, 0.35);
		font-size: 7px;
	}

	.privacy-policy-msg {
		position: absolute;
		bottom: 79px;
		padding-bottom: 10px;
		text-align: center;
		width: 100%;
		color: rgba(white, 0.8);
		font-size: 12px;
		background: linear-gradient(transparent 10%, rgba(0, 0, 0, 0.3));
	}

	.button {
		position: fixed;
		bottom: 0;
		width: 100%;
		padding: 30px;
		border: none;
		background-color: var(--main-buttons-color);
		color: var(--main-buttons-text-color);
		text-transform: uppercase;
	}
}
</style>
